/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnrichedHttpErrorResponse, ENRICH_HTTP_ERROR } from './enriched-http-error-response';

function enrichAndRethrowError(request: HttpRequest<any>) {
  return (error: any) => throwError(new EnrichedHttpErrorResponse({ request, error }));
}

@Injectable()
export class HttpClientErrorEnrichmentInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const shouldEnrich = req.context.get(ENRICH_HTTP_ERROR);

    return shouldEnrich ? next.handle(req).pipe(catchError(enrichAndRethrowError(req))) : next.handle(req);
  }
}
