import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'mri-avatar',
  template: `
    <div *ngIf="iconUrl; else iconTextTmpl" [title]="iconTitle" class="mri-avatar__image ag-home-icon">
      <img [src]="iconUrl" />
    </div>
    <ng-template #iconTextTmpl>
      <span [title]="iconTitle" class="mri-avatar__text">{{ iconText }}</span>
    </ng-template>
  `,
  styles: [
    `
      :host(.invalid) {
        border-color: #ffacae;
        box-shadow: 0 0 0 2px #e72424;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent {
  @Input() @HostBinding('style.backgroundColor') backgroundColor: string | undefined;
  @Input() @HostBinding('class.mri-avatar--border') border = false;
  @Input() @HostBinding('class.invalid') invalid = false;
  @Input() @HostBinding('class.mri-avatar--large') large = false;
  @Input() iconText: string | undefined;
  @Input() iconUrl: string | undefined;
  @Input() iconTitle: string | undefined;

  @HostBinding('class.mri-avatar') readonly hostClass = true;
}
