import { APP_BASE_HREF } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DirtyStatePromptService } from './dirty-state-prompt.service';
import { DirtyStateService } from './dirty-state.service';
import { LOCATION_TOKEN } from './window-location.token';

interface NavigationOptions {
  /**
   * Force navigation even when the current state of the application is dirty
   * @default false
   */
  force?: boolean;
}

@Injectable({ providedIn: 'root' })
export class WindowLocationService {
  constructor(
    private dirtyService: DirtyStateService,
    private promptService: DirtyStatePromptService,
    @Inject(LOCATION_TOKEN) private location: Location,
    @Optional() @Inject(APP_BASE_HREF) private basePath?: string
  ) {}

  /**
   * Use the native `location` service to reload the browser at the `url` supplied. By default, where the
   * application has dirty state, the user will be prompt for confirmation before navigating.
   * @example
   * await this.locationService.navigateTo('/some-path')
   */
  async navigateTo(url: string, options?: NavigationOptions): Promise<boolean> {
    const { force } = options ?? {};
    const proceed = force
      ? true
      : await firstValueFrom(this.dirtyService.confirmWhenDirty$(() => this.promptService.showNavigationPrompt$()));

    if (proceed) {
      this.location.assign(url);
    }
    return proceed;
  }

  /**
   * Use the native `location` service to reload the browser to the base path of the application. By default, where the
   * application has dirty state, the user will be prompt for confirmation before navigating.
   * @example
   * await this.locationService.navigateToBasePath({ force: true })
   */
  navigateToBasePath(options?: NavigationOptions): Promise<boolean> {
    return this.navigateTo(this.basePath ?? '/', options);
  }
}
