export const DashboardMenu = {
  Default: {
    name: 'default',
    text: 'Make this my default',
    icon: 'insert-top'
  },
  Delete: {
    name: 'delete',
    text: 'Delete',
    icon: 'delete'
  },
  DraftEdit: {
    name: 'draftedit',
    text: 'Edit draft',
    icon: 'edit'
  },
  DraftCreate: {
    name: 'draftcreate',
    text: 'Create new draft',
    icon: 'plus'
  },
  DraftPublish: {
    name: 'draftpublish',
    text: 'Publish draft',
    icon: 'upload'
  },
  CreateNewDashboard: {
    name: 'createnewdashboard',
    text: 'Copy as new dashboard',
    icon: 'plus'
  },
  CreateNewFromData: {
    name: 'createnewfromdata',
    text: 'Create a new dashboard for this data',
    icon: 'plus'
  },
  Cancel: {
    name: 'cancel',
    text: 'Cancel',
    icon: 'close'
  },
  Discard: {
    name: 'discard',
    text: 'Discard',
    icon: 'delete'
  },
  ExportToPdf: {
    name: 'exporttopdf',
    text: 'Export To PDF',
    icon: 'file-pdf'
  },
  ExportToPptx: {
    name: 'exporttopptx',
    text: 'Export To PPTX',
    icon: 'file-ppt'
  }
};

export const LinkMenu = {
  Draft: {
    name: 'navigatedraft',
    text: 'Navigate to draft',
    icon: 'hyperlink-open'
  },
  Download: {
    name: 'draftdownload',
    text: 'Download draft zip',
    icon: 'download'
  },
  DatasetInfo: {
    name: 'draftdatasetinfo',
    text: 'Dataset Name',
    icon: 'copy'
  },
  Import: {
    name: 'draftimport',
    text: 'Import Pbix',
    icon: 'upload'
  },
  Dataset: {
    name: 'navigatedataset',
    text: 'Navigate to dataset workspace',
    icon: 'hyperlink-open'
  },
  Version: {
    name: 'navigateversion',
    text: 'Navigate to version workspace',
    icon: 'hyperlink-open'
  }
};

export enum SearchUser {
  SearchInOrganisation = 'SearchInOrganisation',
  SearchAllUsers = 'SearchAllUsers',
  CreateUser = 'CreateUser'
}

export enum BookmarkActions {
  Create = 'created',
  Update = 'updated',
  Delete = 'deleted',
  Rename = 'renamed',
  Apply = 'applied'
}

export const BookmarkMenuItems = {
  AddBookmark: {
    name: 'addBookmark',
    icon: 'plus',
    text: 'Add Bookmark'
  },
  Default: {
    name: 'defaultBookmark',
    text: 'Make Default'
  },
  Apply: {
    name: 'applyBookmark',
    text: 'Apply'
  },
  Rename: {
    name: 'renameBookmark',
    text: 'Rename'
  },
  Update: {
    name: 'updateBookmark',
    text: 'Update'
  },
  Delete: {
    name: 'deleteBookmark',
    text: 'Delete'
  }
};

export const NewDraftState = 'NewDraft';
export const exportInProgress = 'exportInProgress';
export const discardSuccessMessage = 'Draft deleted successfully';
export const exportMessage = 'Your export has been initiated. Download may take some time';
export const bookmarkMessage = (action: BookmarkActions) => `The bookmark has been ${action}`;
export const changeBookmarkDefaultMessage = 'Default bookmark has been updated';
export const noUsersFoundMessage = 'No user found matching search criteria';
export const domainNameWarning = (domainName: string, userDetail?: string) =>
  `${
    userDetail ?? 'Selected user'
  }'s email address does not match your domain (${domainName}). Please verify this is correct before adding user`;
export const userOnboardInfo = (userDetail: string) =>
  `${userDetail} will be available to onboard to other purchased MRI products`;
export const noUserSelectedMessage = 'No User Selected';
export const createUserPromptMessage = (clientId: string) =>
  `This action will create and add the user to the client ${clientId}. Do you wish to continue?`;
export const userPermissionChangePrompt = (title: string) =>
  `The changes that you have selected will impact your access to ${title}. You will be navigated from the current page. Do you wish to continue?`;
export const filterSavePrompt =
  'Saving this filter might undo your changes in the current page. Do you wish to continue?';
export const updateModulePrompt = (workspaceNames: string) =>
  `The following workspace modules contain custom reports: ${workspaceNames}` +
  `\nIf you proceed to remove these modules all the associated reports will be deleted. This action cannot be undone`;
export const userLogoutMessage = 'If you delete this user you will be logged out. Are you sure you want to proceed?';

export enum FileFormat {
  PDF = 'Pdf',
  PPTX = 'Pptx'
}

export const isClientOnboarding = 'isClientOnboarding';
