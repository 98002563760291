import { HttpErrorResponse } from '@angular/common/http';
import { EnrichedHttpErrorResponse } from './enriched-http-error-response';
import { getProblemDetail } from './get-problem-detail';
import { isHttpError } from './is-http-error';
import { SanitizedError } from './sanitized-error';

export function sanitizeHttpClientProblemDetails(err: unknown) {
  const { error, request } = getErrorData(err);
  if (!(error instanceof HttpErrorResponse)) {
    return undefined;
  }

  const problem = getProblemDetail(error);
  if (!problem) {
    return undefined;
  }

  const reason = request ? (request.method === 'GET' ? 'fetch' : 'save') : 'unknown';
  return new SanitizedError({
    message: SanitizedError.getDefaultMessage(reason, problem),
    isError: isHttpError(problem.status, reason === 'fetch'),
    ngOriginalError: error,
    detail: problem
  });
}
function getErrorData(error: unknown): Partial<EnrichedHttpErrorResponse> {
  if (error instanceof EnrichedHttpErrorResponse) {
    return error;
  }
  return { error, request: undefined };
}
