import { Params } from '@angular/router';
import { distinctUntilSomeChanged } from '@rx-angular/state/selections';
import { identity, merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { filter, map, multicast } from 'rxjs/operators';

export type QueryParamMap<T> = {
  [Property in keyof T]: string;
};

/**
 * Create a tryParse function that can be used to parse the value of a query parameters object into a definitely typed
 * object
 * @param type The strongly type query params class that will be used to test and parse query parameters object
 *
 * @example
 *
 * export class CreateDashboardFromDatasetQueryParams {
 *   static keys = keysOf<CreateDashboardFromDatasetQueryParams>({
 *     datasetId: ''
 *   });
 *
 *   static tryParse = createTryParseQueryParam(CreateDashboardFromDatasetQueryParams);
 *
 *   static is(value: Params): value is CreateDashboardFromDatasetQueryParams {
 *     const { datasetId } = value as QueryParamMap<CreateDashboardFromDatasetQueryParams>;
 *     return !!datasetId;
 *   }
 * }
 */
export function createTryParseQueryParam<T extends NonNullable<unknown>>(type: QueryParamType<T>) {
  return (value: Params): T | null => {
    if (!type.is(value)) {
      return null;
    }

    return type.parse ? type.parse(value) : value;
  };
}

export interface QueryParamType<T extends NonNullable<unknown>> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  new (...args: any[]): T;

  keys: Array<keyof T>;

  is(value: Params): value is T;

  parse?: (value: Params) => T;
}

function queryParamsOfOneType<T extends object>(type: QueryParamType<T>) {
  return (source$: Observable<Params>) =>
    source$.pipe(filter(type.is), distinctUntilSomeChanged(type.keys), type.parse ? map(type.parse) : identity);
}

/**
 * Filters `ActivatedRoute.queryParams` `Observable` to those values that satisfy the
 * static `is` predicate function implemented by the `type` parameter supplied.
 *
 * The resulting observable will emit only distinct query param values
 *
 * @param type The strongly type query params that determines the values to emit
 *
 * @example
 *
 * export interface DraftDashboardQueryParams { pbiReportId: string; }
 * export class DraftDashboardQueryParams {
 *   static keys: Array<keyof DraftDashboardQueryParams> = ['pbiReportId'];
 *   static is(value: Params): value is DraftDashboardQueryParams {
 *     return !!(value as DraftDashboardQueryParams).pbiReportId;
 *   }
 * }
 *
 * const draftSelectChange$ = this.route.queryParams.pipe(queryParamsOfType(DraftDashboardQueryParams));
 */
export function queryParamsOfType<T1 extends object>(type: QueryParamType<T1>): OperatorFunction<Params, T1>;

/**
 * Filters `ActivatedRoute.queryParams` `Observable` to those values that satisfy the
 * static `is` predicate function implemented by either `t1` or `t2` type parameters supplied.
 *
 * The resulting observable will emit only distinct query param values
 *
 * @param t1 The strongly type query params that determines the values to emit
 * @param t2 The strongly type query params that determines the values to emit
 *
 * @example
 *
 * interface CreateFromReportQueryParams { reportId: string; workspaceId: string; }
 * class CreateFromReportQueryParams {
 *   static keys: Array<keyof CreateFromReportQueryParams> = ['reportId', 'workspaceId'];
 *   static is(value: Params): value is CreateFromReportQueryParams {
 *     const v = value as CreateFromReportQueryParams;
 *     return !!v.reportId && !!v.workspaceId;
 *   }
 * }
 *
 * interface CreateFromDatasetQueryParams { datasetId: string; }
 * class CreateFromDatasetQueryParams {
 *   static keys: Array<keyof CreateFromDatasetQueryParams> = ['datasetId'];
 *   static is(value: Params): value is CreateFromDatasetQueryParams {
 *     return !!(value as CreateFromDatasetQueryParams).datasetId;
 *   }
 * }
 *
 * const dataSourceChange$ = this.route.queryParams.pipe(
 *   queryParamsOfType(CreateFromDatasetQueryParams, CreateFromReportQueryParams)
 * );
 *
 * const createToken$ = dataSourceChange$.pipe(
 *   switchMap(p => {
 *     return CreateFromDatasetQueryParams.is(p)
 *       ? dashboardService.getEmbedTokenForDataset(p.datasetId)
 *       : dashboardService.getEmbedTokenFor({
 *           reportId: p.reportId,
 *           pbiWorkspaceId: p.workspaceId
 *         });
 *   })
 * );
 */
export function queryParamsOfType<T1 extends object, T2 extends object>(
  t1: QueryParamType<T1>,
  t2: QueryParamType<T2>
): OperatorFunction<Params, T1 | T2>;
/**
 * Filters `ActivatedRoute.queryParams` `Observable` to those values that satisfy the
 * static `is` predicate function implemented by any of the type parameters supplied.
 *
 * The resulting observable will emit only distinct query param values
 */
export function queryParamsOfType<T1 extends object, T2 extends object, T3 extends object>(
  t1: QueryParamType<T1>,
  t2: QueryParamType<T2>,
  t3: QueryParamType<T3>
): OperatorFunction<Params, T1 | T2 | T3>;
export function queryParamsOfType(...types: QueryParamType<Params>[]): OperatorFunction<Params, Params> {
  return (source$: Observable<Params>) =>
    source$.pipe(
      multicast(
        () => new Subject<Params>(),
        shared$ => merge(...types.map(t => shared$.pipe(queryParamsOfOneType(t))))
      )
    );
}
