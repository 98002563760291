/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

function rethrowAsJsonError() {
  return (err: HttpErrorResponse) => {
    const { error: maybeBlob, headers, status, statusText, url } = err;
    if (maybeBlob instanceof Blob && headers.get('content-type')?.includes('json')) {
      const jsonErrorPromise = new Promise<never>((_, reject) => {
        maybeBlob
          .text()
          .then(JSON.parse)
          .then(jsonError => {
            reject(
              new HttpErrorResponse({
                error: jsonError,
                headers,
                status,
                statusText,
                url: url ?? undefined
              })
            );
          });
      });
      return from(jsonErrorPromise);
    }
    return throwError(err);
  };
}
@Injectable()
export class HttpClientBlobErrorInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isBlobResponseType = req.responseType === 'blob';
    return isBlobResponseType ? next.handle(req).pipe(catchError(rethrowAsJsonError())) : next.handle(req);
  }
}
