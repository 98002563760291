export function isHttpError(statusCode: number, isFetch: boolean) {
  if (statusCode === 0) {
    // status code 0 usually means the request to the server is cancelled or the server is not returning any response.
    // unfortunately there isn't anyway to distinguish between client-side cancelling the request and the server
    // not responding
    // in practice treated this as an error is probably the most reasonable thing to do because:
    // * if the caller is using a promise to wait on the HttpClient response, then promises can't be cancelled
    //   therefore the underlying xhr object will not be cancelled even if the caller that started the promise is no
    //   longer listening to the promise outcome. this has the implication that where calling code is using promises,
    //   then any status code of 0 is NOT going to be as a result of a client-side cancelling the request
    // * if the caller is using observables and they cancel the request by unsubscribing from the observable created
    //   for the http request, then by the nature of observables that are unsubscribed from, no code will run that would
    //   be receiving the cancellation HttpErrorResponse. therefore there won't be code trying to log the cancellation
    //   as an error
    return true;
  } else if (statusCode < 400) {
    return false;
  } else if (isFetch) {
    return statusCode >= 400;
  } else {
    const isModelWriteValidation = statusCode === 400 || statusCode === 422;
    return !isModelWriteValidation;
  }
}
